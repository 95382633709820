const copyDeepObject = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

// Set intiger only input in text box. Prevent any other character input.
const setIntegerOnly = (event: any) => {
  const re = /^[0-9\b]+$/;
  if (!re.test(event.key) && event.key !== "Backspace") {
    event.preventDefault();
  }
};

const setDecimalOnly = (event: any) => {
  const re = /^[0-9\b.]+$/;
  const reKeys = ["Delete", "Backspace", "ArrowRight", "ArrowLeft", "Tab"];

  if (!reKeys.includes(event.key) && (!re.test(event.key) || (event.key === '.' && event.target.value.includes('.')))) {
    event.preventDefault();
  }
};

const REQ_HEADER_CONFIG = {
  headers: { Authorization: `Bearer ${sessionStorage.getItem("id_token")}` },
};

const setBodyCssClass = (name: string) => {
  document.body.classList.add(name);
};

const removeBodyCssClass = (name: string) => {
  document.body.classList.remove(name);
};

const addLeadingZero = (number: number | string) => Number(number).toString().padStart(2, '0');

const truncateString = (str: string, maxLength: number) => {
  const length = str.length;
  if (length > maxLength) {
    return str.substring(0, maxLength) + "...";
  }

  return str;
}

const getRawWorkOrderNumber = (workOrderNumber: string) => {
  const numberLength = workOrderNumber.length
  if (numberLength > 0 && workOrderNumber.startsWith('0000')) {
    return workOrderNumber.substring(4, numberLength);
  }
  return workOrderNumber;
}

const sortListByKey = (list: any[], keyName: string): any[] => {
  if (list && list.length > 0) {
    return list.sort((a: any, b: any) => {
      if (isString(a[keyName]) && isString(b[keyName])) {
        return a[keyName].localeCompare(b[keyName]);
      }
      return false;
    })
  }
  return list;
}

const isString = (value: any): boolean => {
  return typeof(value) === "string";
}
const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key];

const officialDomain = process.env.REACT_APP_PETRONAS_EMAIL_DOMAIN;
const domainRegex: RegExp = new RegExp(`@${officialDomain?.replace(/\./g, '\\.')}$`, 'i')

const isValidPetronasEmail = (emailStr: string) => {
  if (!domainRegex.test(emailStr)) {
    return false;
  }
  return true;
}

const openLinkInNewTab = (url: string) => {
  window.open(url, "_blank");
}

const standardizeMHR = (mhr: number) => {
  let formattedMhr = Number(mhr).toFixed(1);
  if (isNaN(Number(formattedMhr))) {
    return '00.0MHR';
  }
  let [integerPart, decimalPart] = formattedMhr.split('.');

  return `${integerPart.padStart(2, '0')}.${decimalPart}MHR`;
}

const checkHourCondition = (hour: number) => {
  return `${hour < 10 ? '0' : ''}${hour} ${hour > 1 ? 'hrs' : 'hr'}`;
}
export { copyDeepObject, setIntegerOnly, setDecimalOnly, REQ_HEADER_CONFIG, setBodyCssClass, removeBodyCssClass, addLeadingZero, truncateString, getKeyValue, isValidPetronasEmail, getRawWorkOrderNumber, openLinkInNewTab, standardizeMHR, sortListByKey, checkHourCondition };
