import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./slices/appSlice";
import orderReducer from "./slices/orderSlice";
import teamListReducer from "./slices/teamListSlice";
import teamResourceListReducer from "./slices/team-resource/teamResourceListSlice";
import teamInternalListReducer from "./slices/team-resource/teamInternalListSlice";
import teamHourListReducer from "./slices/team-resource/teamDefaultHourSlice";
import teamExternalListReducer from "./slices/team-resource/teamExternalListSlice";
import nonWorkHourListReducer from "./slices/team-resource/nonWorkHour/nonWorkHourSlice";
import resourceListReducer from "./slices/search/resource/searchResourceSlice";
import woGlobalValueReducer from "./slices/global/woGlobalValueSlice";
import resourceDetailsReducer from "./slices/global/resourceDetailsSlice";
import userManagementReducer from "./slices/admin-module/userManagementSlice";
import teamManagementReducer from './slices/admin-module/teamManagementSlice';
import commonReducer from './slices/common/commonSlice';
import { technicianBoardRootReducer } from "./slices/technician-board/technicianBoardSlice";

import { useDispatch } from "react-redux";
import { craftRootReducer } from "./slices/craft/craftSlice";
import { schedulingRootReducer } from "./slices/scheduling/schedulingSlice";
import { myGovernanceRootReducer } from "./slices/my-governance/myGovernanceRootReducer";
import { integrationRecordActionRootReducer } from "./slices/integration-record-action/integrationRecordActionRootReducer";
import { ePTWRootReducer } from "./slices/ePTW/ePTWRootReducer";
import { exportRootReducer } from "./slices/export/exportRootReducer";
import { crewBaseRoootReducer } from "./slices/crew/crewBaseRootReducer";
import { operationRootReducer } from "./slices/operation/operationRootReducer";

const store = configureStore({
  reducer: {
    app: appReducer,
    orders: orderReducer,
    teamList: teamListReducer,
    teamResourceList: teamResourceListReducer,
    teamInternalList: teamInternalListReducer,
    scheduling: schedulingRootReducer,
    teamHourList: teamHourListReducer,
    teamExternalList: teamExternalListReducer,
    nonWorkList: nonWorkHourListReducer,
    resourceList: resourceListReducer,
    craft: craftRootReducer,
    woGlobalValue: woGlobalValueReducer,
    resourceDetails: resourceDetailsReducer,
    userManagement: userManagementReducer,
    teamManagement: teamManagementReducer,
    technicianBoard: technicianBoardRootReducer,
    common: commonReducer,
    myGovernance: myGovernanceRootReducer,
    integrationRecord: integrationRecordActionRootReducer,
    ptw: ePTWRootReducer,
    export: exportRootReducer,
    crew: crewBaseRoootReducer,
    operationRoot: operationRootReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export default store;
